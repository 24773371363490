<template>
	<div>
		<div v-if="edit">
			<Editor v-model="new_val" :multiligne="true" :tokenSelect="$route.name === 'mail' || $route.name === 'mailtemplate'" :pevents="pevents" />
		</div>
		<div v-else>
			<div :class="[full ? 'full' : 'truncate', classes]" class="html-content text-truncate" :style="[full ? {}:{height: '63px'}, styles]" v-html="content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HtmlArea",
		props: ['value', 'content', 'edit', 'full', 'styles', 'classes', 'pevents'],
		components: {
			Editor: () => import('@/components/editor/Editor.vue'),
			TokenSelect: () => import('@/components/fields/TokenSelect.vue')
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>
	.html-content >>> * {
		font-size: 1.3rem;
	}

	.truncate.html-content >>> br {
		display: none;
	}

	.full.html-content >>> h1 {
		font-size: 2rem;
	}
	.full.html-content >>> h2 {
		font-size: 1.8rem;
	}
	.full.html-content >>> h3 {
		font-size: 1.6rem;
	}
	.full.html-content >>> h4 {
		font-size: 1.4rem;
	}
	.full.html-content >>> h5, .full.html-content >>> h6, .full.html-content >>> p {
		font-size: 1.3rem;
	}
	.full.html-content {
		//text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
	}

	.editor >>> blockquote {
        border-left: 3px solid rgba(0,0,0,.1);
        color: var(--v-label-base);
        padding-left: .8rem;
        font-style: italic;
		margin: 20px auto;
    }
	.html-content >>> table {
        border-collapse: collapse;
        margin-bottom: 10px;
    }
    .html-content >>> td{
        border: 1px solid var(--v-black2white-base) !important;
        min-width: 30px;
        cursor: col-resize !important;
        padding: 5px;
    }
</style>
